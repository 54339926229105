import React, { useState, useMemo } from "react";
import { styled } from "@mui/material/styles";
import { graphql } from "gatsby";
import { Button, Typography } from "@mui/material";
import { EmailRounded, Build } from "@mui/icons-material";
import { AnchorLink as Link } from "@prismatic-io/gatsby-plugin-anchor-links";

import LandingPage from "@/page-components/landing";
import SearchBox from "@/components/SearchBox";
import StepCard from "@/components/StepCard";
import { Markdown } from "@/components/markdown";
import { SEO } from "@/layout/SEO";
import { avoidAdBlockPattern } from "@/utils/adblock";
import { colors } from "@/theme/colors";

const PREFIX = "ComponentsPage";

const classes = {
  container: `${PREFIX}-container`,
  content: `${PREFIX}-content`,
  componentsContainer: `${PREFIX}-componentsContainer`,
  componentCards: `${PREFIX}-componentCards`,
  cardContainer: `${PREFIX}-cardContainer`,
  searchContainer: `${PREFIX}-searchContainer`,
  moreInfoContainer: `${PREFIX}-moreInfoContainer`,
  categories: `${PREFIX}-categories`,
  missingComponentHeader: `${PREFIX}-missingComponentHeader`,
  infoItem: `${PREFIX}-infoItem`,
};

const ComponentsPageStyled = styled("div")(({ theme }) => ({
  position: "relative",
  margin: "0 auto",
  maxWidth: 914,
  paddingBottom: "2em",

  [`& .${classes.content}`]: {
    marginBottom: "3em",
  },

  [`& .${classes.componentsContainer}`]: {
    margin: "0 auto",
  },

  [`& .${classes.componentCards}`]: {
    margin: "0 auto",
    display: "flex",
    flexWrap: "wrap",
  },

  [`& .${classes.cardContainer}`]: {
    padding: "1em",
    flex: "0 0 33.333333%",

    [theme.breakpoints.down(800)]: {
      flexBasis: "50%",
    },
    [theme.breakpoints.down(600)]: {
      flexBasis: "100%",
    },
  },

  [`& .${classes.searchContainer}`]: {
    display: "flex",
    justifyContent: "center",

    "& > *": {
      flex: 1,
      margin: "8px 12px",
    },
  },

  [`& .${classes.moreInfoContainer}`]: {
    maxWidth: 700,
    margin: "0 auto",
    padding: "0 1.5em",
  },

  [`& .${classes.categories}`]: {
    margin: "0.5em",

    "& > *": {
      margin: "0.5em",
    },
  },

  [`& .${classes.missingComponentHeader}`]: {
    textAlign: "center",
    marginBottom: "1em",
  },

  [`& .${classes.infoItem}`]: {
    display: "flex",
    alignItems: "flex-start",
    marginBottom: "1em",

    "& > svg": {
      marginRight: "1em",
      marginTop: 6,
      color: colors.mintGreen,
      fontSize: "2em",
    },

    "& > *:last-child p:first-of-type": {
      marginTop: 0,
    },
  },
}));

const ComponentsPage = ({ data, location, pageContext }) => {
  const [search, setSearch] = useState("");
  const [filterCategory, setFilterCategory] = useState(undefined);

  const { moreInfo, ...formData } = data.markdownRemark.frontmatter;
  const { components, categories } = pageContext;

  const filteredComponents = useMemo(() => {
    if (!search && !filterCategory) {
      return components;
    }

    const searchTerm = search.toLowerCase();

    return components.filter((component) => {
      const searchMatch =
        searchTerm === "" || component.label.toLowerCase().includes(searchTerm);

      const categoryMatch =
        filterCategory === undefined || component.category === filterCategory;

      return searchMatch && categoryMatch;
    });
  }, [components, search, filterCategory]);

  return (
    <LandingPage {...formData} location={location}>
      <ComponentsPageStyled>
        <div className={classes.content}>
          <div className={classes.searchContainer}>
            <SearchBox
              value={search}
              onChange={(event) => {
                setFilterCategory(undefined);
                setSearch(event.target.value);
              }}
              placeholder="Search components"
            />
          </div>
          <div className={classes.categories}>
            <Button
              variant="outlined"
              onClick={() => {
                setSearch("");
                setFilterCategory(undefined);
              }}
              color={filterCategory === undefined ? "secondary" : undefined}
            >
              All
            </Button>
            {categories.map((categoryName) => (
              <Button
                variant="outlined"
                key={categoryName}
                onClick={() => {
                  setSearch("");
                  setFilterCategory(categoryName);
                }}
                color={
                  filterCategory === categoryName ? "secondary" : undefined
                }
              >
                {categoryName}
              </Button>
            ))}
          </div>
          <div className={classes.componentsContainer}>
            <div className={classes.componentCards}>
              {filteredComponents.map((component) => (
                <div className={classes.cardContainer} key={component.key}>
                  <StepCard
                    comingSoon={component.comingSoon}
                    component={component.comingSoon ? "div" : Link}
                    to={
                      component.comingSoon
                        ? undefined
                        : `/components/${avoidAdBlockPattern(component.key)}/`
                    }
                    primary={component.label}
                    secondary={
                      <>
                        {component.actionCount} action
                        {component.actionCount !== 1 && "s"}
                      </>
                    }
                    image={data.componentImages.nodes.find(
                      ({ name }) => name === component.keySha1
                    )}
                  />
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className={classes.moreInfoContainer}>
          <Typography
            variant="h4"
            component="h2"
            className={classes.missingComponentHeader}
          >
            {moreInfo.heading}
          </Typography>

          <div className={classes.infoItem}>
            <EmailRounded />
            <Markdown html={moreInfo.primary} />
          </div>
          <div className={classes.infoItem}>
            <Build />
            <Markdown html={moreInfo.secondary} />
          </div>
        </div>
      </ComponentsPageStyled>
    </LandingPage>
  );
};

export const query = graphql`
  query getComponentsData {
    componentImages: allFile(
      filter: { relativePath: { glob: "components/**" } }
    ) {
      nodes {
        id
        name
        childImageSharp {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: NONE
            quality: 85
            width: 42
          )
        }
      }
    }
    markdownRemark(fileAbsolutePath: { glob: "**/pages/components.md" }) {
      frontmatter {
        ...SeoFields
        minimalNavigation
        moreInfo {
          heading
          primary
          secondary
        }
        customFooterCta {
          primary
          secondary
          primaryCta {
            text
            link
            type
          }
          secondaryCta {
            text
            link
            type
          }
        }
        sections {
          title
          callToActions {
            text
            link
            type
          }
          markdown
          media {
            appFrame
            alternateText
            caption
            video
            screenshot {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: NONE
                  quality: 85
                  width: 650
                )
              }
            }
          }
          type
          gridItems {
            markdown
            appFrame
            alternateText
            gridImage {
              childImageSharp {
                gatsbyImageData(
                  layout: CONSTRAINED
                  placeholder: NONE
                  quality: 85
                  width: 650
                )
              }
            }
          }
          features {
            icon
            markdown
          }
        }
        form {
          headline
          name
          text
          theme
          checklistSection
          testimonialSection
          markdownSection
          imageSection {
            childImageSharp {
              gatsbyImageData(
                layout: CONSTRAINED
                placeholder: NONE
                quality: 85
                width: 550
              )
            }
          }
          logoSection {
            title
            logos {
              title
              image {
                publicURL
              }
            }
          }
          submitAreaMarkdown
          submit {
            text
            loadingText
          }
          fields {
            name
            label
            type
            validation
            validationMatch
            defaultValue
            autoComplete
            identifier
            hideFromSegment
          }
          defaultParams {
            key
            value
          }
          confirmation {
            redirect {
              route
              type
            }
            primary
            secondary
            confirmationImage {
              childImageSharp {
                gatsbyImageData(
                  layout: FIXED
                  placeholder: NONE
                  quality: 85
                  width: 200
                )
              }
            }
          }
          subdomain
          route
          errorMessage
        }
      }
    }
  }
`;

export const Head = ({ location, data, params, pageContext }) => {
  const {
    author,
    description,
    metaDescription,
    robots,
    schemaOrg,
    seoImage,
    title,
    titleTag,
  } = data.markdownRemark.frontmatter;

  return (
    <SEO
      author={author}
      description={description}
      metaDescription={metaDescription}
      pathname={location.pathname}
      robots={robots}
      schemaOrg={schemaOrg}
      seoImage={seoImage}
      title={title}
      titleTag={titleTag}
    />
  );
};

export default ComponentsPage;
