import React from "react";
import { styled } from "@mui/material/styles";
import { Paper } from "@mui/material";
import { GatsbyImage } from "gatsby-plugin-image";
import clsx from "clsx";

import Category from "@/page-components/blog/category";
import { colors } from "@/theme/colors";
import { transparent } from "@/utils/transparent";

const PREFIX = "StepCard";

const classes = {
  notClickable: `${PREFIX}-notClickable`,
  selected: `${PREFIX}-selected`,
  container: `${PREFIX}-container`,
  removeContainer: `${PREFIX}-removeContainer`,
  removeButton: `${PREFIX}-removeButton`,
  imageContainer: `${PREFIX}-imageContainer`,
  placeholder: `${PREFIX}-placeholder`,
  content: `${PREFIX}-content`,
  primary: `${PREFIX}-primary`,
  secondary: `${PREFIX}-secondary`,
  categoryContainer: `${PREFIX}-categoryContainer`,
};

const StepCardStyled = styled(Paper)(() => {
  const borderLikeShadow = `0px 0px 0px 2px ${colors.turquoise}`;
  const blurredShadow = `1px 3px 11px 1px ${transparent(
    colors.turquoise,
    "0.10"
  )}`;
  const selectedBoxShadow = `${borderLikeShadow}, ${blurredShadow}`;

  return {
    cursor: "pointer",
    background: colors.white01,
    borderRadius: 4,
    boxShadow: `0px 0px 0px 1px ${colors.gray04}, 1px 3px 11px 1px rgba(60, 64, 67, 0.22)`,
    position: "relative",
    overflow: "hidden",
    display: "block",

    [`&.${classes.notClickable}`]: {
      cursor: "initial",
    },

    [`&.${classes.selected}`]: {
      boxShadow: selectedBoxShadow,
    },

    [`& .${classes.container}`]: {
      display: "flex",
      padding: "1.2em 0.5em",
    },

    [`& .${classes.removeContainer}`]: {
      position: "absolute",
      right: "0.1em",
      top: "0.1em",
    },

    [`& .${classes.removeButton}`]: {
      padding: "0.15em",
      "& svg": {
        width: "0.65em",
        height: "0.65em",
      },
    },

    [`& .${classes.imageContainer}`]: {
      width: "3em",
      height: "3em",
      marginRight: "1em",
      marginLeft: "0.6em",
      borderRadius: "3em",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      color: colors.white01,
      "& .MuiSvgIcon-root": {
        fontSize: "3em",
        fill: colors.blueIris,
        background: colors.white01,
      },
      "& img": {
        width: "3em",
      },
    },

    [`& .${classes.placeholder}`]: {
      background: colors.periwinkle,
    },

    [`& .${classes.content}`]: {
      flex: 2,
      display: "flex",
      justifyContent: "space-between",
      alignItems: "center",
      minWidth: "10em",
      paddingRight: "1em",
    },

    [`& .${classes.primary}`]: {
      maxWidth: "20em",
      textOverflow: "ellipsis",
      overflow: "hidden",
      fontWeight: 800,
      fontSize: "1.2em",
    },

    [`& .${classes.secondary}`]: {
      color: colors.gray08,
    },

    [`& .${classes.categoryContainer}`]: {
      position: "absolute",
      right: 6,
      top: 4,

      "& *": {
        lineHeight: "15.12px",
        fontSize: "10.08px",
      },
    },
  };
});

const StepCard = ({
  primary,
  secondary,
  className,
  selected,
  image,
  children,
  ref,
  comingSoon,
  style,
  ...rest
}) => {
  return (
    <StepCardStyled
      className={clsx(
        selected && classes.selected,
        !rest.to && classes.notClickable,
        className
      )}
      data-step-name={primary}
      ref={ref}
      style={style}
      {...rest}
    >
      <div className={classes.container}>
        {comingSoon && (
          <div className={classes.categoryContainer}>
            <Category category="Coming Soon" />
          </div>
        )}
        {image ? (
          <div className={classes.imageContainer}>
            <GatsbyImage
              objectFit="scale-down"
              alt={`${primary} Component`}
              image={image.childImageSharp.gatsbyImageData}
            />
          </div>
        ) : null}
        <div className={classes.content}>
          <div className={classes.primary}>{primary}</div>
        </div>
      </div>
      {children}
    </StepCardStyled>
  );
};

export default StepCard;
