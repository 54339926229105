/** Avoid ad block patterns.
 *
 * Easylist has over 30 lines of patterns attempting to prevent
 * any XHR request containing various permutations of "google-ads"
 * from being successfully downloaded. This breaks our component
 * detail page for Google Ads (appears New Relic is unaffected even
 * though it was affected by blocking for its images oddly enough).
 */
export const avoidAdBlockPattern = (value: string): string => {
  if (value === "google-ads") {
    return value.replace("-", "/");
  }
  return value;
};
