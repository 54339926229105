import React, { useMemo } from "react";
import { styled } from "@mui/material/styles";
import { useStaticQuery, graphql } from "gatsby";

import { colors } from "@/theme/colors";

const PREFIX = "Category";

const classes = {
  category: `${PREFIX}-category`,
};

const CategoryStyled = styled("div")(() => ({
  display: "flex",

  [`& .${classes.category}`]: {
    textTransform: "uppercase",
    fontSize: "0.8em",
    marginTop: 3,
    backgroundColor: colors.blueIris,
    color: colors.white01,
    padding: "1px 3px",
    borderRadius: "0.1em",
    fontWeight: 500,
  },
}));

const Category = ({ category }) => {
  const { meta } = useStaticQuery(
    graphql`
      query getCategories {
        meta: markdownRemark(
          fileAbsolutePath: { glob: "**/content/site-meta.md" }
        ) {
          frontmatter {
            blogCategories {
              name
              color
              customColor
            }
          }
        }
      }
    `
  );

  const { blogCategories } = meta.frontmatter;

  const match = useMemo(
    () => blogCategories.find(({ name }) => name === category),
    [blogCategories, category]
  );

  const backgroundColor = useMemo(
    () => (match ? match.customColor || colors[match.color] : undefined),
    [match]
  );

  if (!category) {
    return null;
  }

  return (
    <CategoryStyled>
      <div className={classes.category} style={{ backgroundColor }}>
        {category}
      </div>
    </CategoryStyled>
  );
};

export default Category;
