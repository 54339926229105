import React from "react";
import { styled } from "@mui/material/styles";
import { InputAdornment, TextField } from "@mui/material";
import { SearchOutlined } from "@mui/icons-material";

import { colors } from "@/theme/colors";

const PREFIX = "SearchBox";

const classes = {
  icon: `${PREFIX}-icon`,
};

const SearchBoxStyled = styled(TextField)(({ theme }) => ({
  margin: theme.spacing(1),

  "& .MuiInputBase-input": {
    padding: "12px 10px",
  },
  "& .MuiFilledInput-root": {
    borderRadius: 8,
    "&.Mui-focused": {
      backgroundColor: colors.gray04,
    },
    "&::before": {
      borderBottom: "none !important",
    },
  },
  "& .MuiFilledInput-underline:after": {
    borderBottom: "none",
  },

  [`& .${classes.icon}`]: {
    color: colors.gray08,
    marginTop: -12,
  },
}));

export {};

const SearchBox = ({ className, ...props }) => {
  return (
    <SearchBoxStyled
      variant="filled"
      className={className}
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <SearchOutlined className={classes.icon} />
          </InputAdornment>
        ),
      }}
      {...props}
    />
  );
};

export default SearchBox;
